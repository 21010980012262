import {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {motion, useAnimation, useCycle} from "framer-motion";

const welcomeText = "\"joaquin munoz\"";

const cursorVariants = {
  "HIDDEN": { opacity: 0 },
  "INACTIVE": { opacity: 1, backgroundColor: "#000000ff", transition: {duration: 0, delay: 0.75} },
  "ACTIVE": { opacity: 1, backgroundColor: "#00000000", transition: {duration: 0, delay: 0.75} }
}

function IntroText() {

  const animationLetters = welcomeText.split("").map(() => useAnimation());
  const [visibleLoop, setVisibleLoop] = useState(false);
  const [loopStatus, nextLoopStatus] = useCycle("INACTIVE", "ACTIVE")

  useEffect(() => {
    (async function() {
      setVisibleLoop(false);
      for(let el of animationLetters) {
        await el.start({opacity: 0, transition: {duration: 0}});
      }

      await animationLetters[0].start({opacity: 0, transition: {duration: 0}});
      await animationLetters[0].start({opacity: 1, backgroundColor: "#000000ff", transition: {duration: 0, delay: 0.75}});
      await animationLetters[0].start({opacity: 0, transition: {duration: 0, delay: 0.75}});
      await animationLetters[0].start({opacity: 1, transition: {duration: 0, delay: 0.75}});
      await animationLetters[0].start({opacity: 0, transition: {duration: 0, delay: 0.75}});

      for (let i = 0; i < animationLetters.length - 1; i++) {
        const current = animationLetters[i];
        const next = animationLetters[i+1];

        await current.start({opacity: 1, backgroundColor: "#000000ff", transition: {duration: 0, delay: 0.025}});
        await Promise.all([
          current.start({opacity: 1, backgroundColor: "#00000000", transition: {duration: 0, delay: 0.025}}),
          next.start({opacity: 1, backgroundColor: "#000000FF", transition: {duration: 0, delay: 0.025}})
        ])
      }
      await animationLetters[
        animationLetters.length - 1
        ].start({opacity: 1, backgroundColor: "#00000000", transition: {duration: 0, delay: 0.025}});

      setVisibleLoop(true);
    })();
  }, []);

  return (
    <h1 className="text-5xl font-mono select-none">
      $~
      {welcomeText.split("").map((char, index) => (
        <motion.span key={`char-${char}-${index}`} className="opacity-0" animate={animationLetters[index]}>
          {char}
        </motion.span>
      ))}
      <motion.span
        className="opacity-0" initial="HIDDEN"
        variants={cursorVariants} onAnimationComplete={() => nextLoopStatus()}
        animate={visibleLoop ? loopStatus : "HIDDEN"}
      >&nbsp;</motion.span>
    </h1>
  );

}

export default function IndexPage() {
  return (
    <>
      <Helmet>
        <title>joaquin.muñoz.dev - Personal website</title>
      </Helmet>
      <main className="w-full h-screen flex flex-col items-center justify-center content-center">
        <div className="w-full h-full flex flex-1 text-center items-center justify-center content-center">
          <IntroText />
        </div>
      </main>
    </>
  );
}